import React from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function ServiceProgressArea() {
  const brandingProgress = 85;
  const digitalMarketProgress = 73;
  const UIUXProgress = 93;
  return (
    <>
      <div className="row pt-120 d-flex justify-content-center">
        <div className="col-lg-6 d-lg-block d-none">
          <div
            className="about-img wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/bg/mobiledev.png"}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h5>Mobile App Development Services</h5>
              <h3>Innovative Solutions for Mobile Excellence</h3>
              <p>
                At Green Hood Tech, our passion lies in harnessing the power of
                technology to fuel innovation and facilitate the growth of
                businesses in the dynamic landscape of the digital era. With a
                team of dedicated experts and a steadfast commitment to
                excellence, we deliver a wide array of IT solutions meticulously
                crafted to address the distinct requirements of each of our
                clients. Our holistic approach ensures that we not only meet but
                exceed expectations, enabling businesses to stay ahead of the
                curve and achieve sustainable success.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>iOS App Development:</h3>
              <p>
                Harness the power of the iOS platform with our custom-built
                applications tailored for iPhones, iPads, and other Apple
                devices.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="1.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>Android App Development:</h3>
              <p>
                Reach a wider audience with our Android app development
                services, designed to deliver seamless experiences across a
                variety of devices and screen sizes.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="1.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>Cross-Platform Development: </h3>
              <p>
                Save time and resources with our cross-platform development
                solutions, allowing you to deploy your app on multiple platforms
                with a single codebase.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>UI/UX Design: </h3>
              <p>
                Our team of designers will create stunning and intuitive
                interfaces that captivate users and enhance their overall
                experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>Backend Development: </h3>
              <p>
                We offer robust backend development services to ensure that your
                app runs smoothly and securely, with features such as user
                authentication, data storage, and more.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>iOS App Development:</h5> */}
              <h3>Quality Assurance:</h3>
              <p>
                Our rigorous testing process ensures that your app is bug-free
                and performs flawlessly across different devices and scenarios.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-120 d-flex justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h5>Web Development Services</h5>
              <h3>Crafting Dynamic and Functional Websites</h3>
              <p>
                At Green Hood Tech, we specialize in creating bespoke websites
                that blend creativity with cutting-edge technology. Our team of
                experienced developers and designers work in synergy to bring
                your vision to life, delivering websites that are not only
                visually stunning but also optimized for performance and
                functionality.
              </p>
              {/* <div className="counter-wrapper d-flex justify-content-lg-between justify-content-center flex-wrap gap-2">
                <div className="counter-item">
                  <CircularProgressbar
                    value={brandingProgress}
                    text={`${brandingProgress}%`}
                    className="progress-bar-circle circle_percent"
                  />
                  <h4>Branding Design</h4>
                </div>
                <div className="counter-item">
                  <CircularProgressbar
                    value={digitalMarketProgress}
                    text={`${digitalMarketProgress}%`}
                    className="progress-bar-circle circle_percent"
                  />
                  <h4>Digital Marketing</h4>
                </div>
                <div className="counter-item">
                  <CircularProgressbar
                    value={UIUXProgress}
                    text={`${UIUXProgress}%`}
                    className="progress-bar-circle circle_percent"
                  />
                  <h4>Ui/Ux Design</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <div
            className="about-img wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/bg/webdev.png"}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>Custom Web Design:</h3>
              <p>
                Our team of designers will create a unique and captivating
                design that captures the essence of your brand while providing
                an intuitive user experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>Responsive Development:</h3>
              <p>
                We ensure that your website looks and performs flawlessly across
                all devices, from desktops to smartphones and tablets.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>Content Management Systems:</h3>
              <p>
                We specialize in building websites on robust CMS platforms such
                as WordPress, Drupal, and Joomla, giving you full control over
                your content.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>E-commerce Solutions:</h3>
              <p>
                Whether you're a small boutique or a large enterprise, we can
                create a secure and scalable e-commerce website that drives
                sales and conversions.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>Web Application Development:</h3>
              <p>
                From custom web applications to enterprise portals, we have the
                expertise to develop powerful solutions tailored to your unique
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h3>Search Engine Optimization:</h3>
              <p>
                Our SEO experts will optimize your website to improve its
                visibility on search engines, driving organic traffic and
                boosting your online presence.
              </p>
            </div>
          </div>
        </div>
      </div>
    
      <div className="row pt-120 d-flex justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              <h5>Software as a Service (SaaS)</h5>
              <h3>The Future of Software Delivery</h3>
              <p>
                Software as a Service (SaaS) represents a revolutionary approach
                to software delivery and consumption. Unlike traditional
                software models where applications are purchased, installed, and
                maintained on individual devices, SaaS delivers software over
                the internet as a subscription-based service.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <div
            className="about-img wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/bg/sassdev.png"}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>How It Works:</h5> */}
              <h3>How It Works:</h3>
              <p>
                With SaaS, users access applications via a web browser or API,
                eliminating complex installations and updates. The software is
                hosted and maintained by the service provider.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>Key Features</h5> */}
              <h3>Accessibility:</h3>
              <p>
                SaaS applications are accessible from any device with an
                internet connection, enabling users to work from anywhere, at
                any time.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>Key Features</h5> */}
              <h3>Scalability:</h3>
              <p>
                SaaS solutions are highly scalable, allowing businesses to
                easily adjust their usage based on demand without the need for
                additional infrastructure investments.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>Key Features</h5> */}
              <h3>Cost-effectiveness:</h3>
              <p>
                SaaS operates on a subscription-based model, offering
                predictable pricing and eliminating upfront capital expenditures
                associated with traditional software purchases.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>Key Features</h5> */}
              <h3>Automatic Updates:</h3>
              <p>
                Service providers roll out updates and enhancements seamlessly,
                ensuring that users always have access to the latest features
                and security patches.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8">
          <div
            className="about-card h-100 wow animate fadeInDown"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            <div className="about-content">
              {/* <h5>Key Features</h5> */}
              <h3>Integration</h3>
              <p>
                SaaS applications can often be integrated with other software
                systems and services, enabling seamless data exchange and
                workflow automation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProgressArea;
