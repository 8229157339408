import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);
function TechStack() {
  const txt = {
    "-webkit-text-stroke": " 2px #2d2d2d",
    "-webkit-text-fill-color": "transparent",
    "font-size": "50px",
    "font-weight": "900",
    "margin-top": "-25px",
  };
  const style = { width: "200px", margin: "auto" };
  const clrl = { color: "#76ac4b", opacity:1,left:'0px'  };
  const clrr = { color: "#76ac4b", opacity:1,right:'0px'  };
  const sponsorSlide = {
    slidesPerView: 5,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      // when window width is >= 640px
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      // when window width is >= 1400px
      1400: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
    },
  };
  return (
    <>
      <div className="sponsor-section">
        <div className="container" style={{maxWidth:'1500px'}}>
          <div className="row">
            <h1 style={txt} className="text-center mb-4">
              Technical Excellence
            </h1>
            <Swiper {...sponsorSlide} className="swiper sponsor-slider-dark">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item ">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/Azure.png"
                      }
                      className="mx-auto"
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={process.env.PUBLIC_URL + "/images/techstack/aws.png"}
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={process.env.PUBLIC_URL + "/images/techstack/CGP.png"}
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={process.env.PUBLIC_URL + "/images/techstack/ios.png"}
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/android.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/Ionic.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/React.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/Angular.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/core.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/Csharp.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/mongodb.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/mysql.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/sqlserver.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/micro.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL + "/images/techstack/agil.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/Javascript.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/Typescript.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/nodejs.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/ajax.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/jquery.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Link to={"#"} className="sponsor-item">
                    <img
                      style={style}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/techstack/swager.png"
                      }
                      alt="images"
                    />
                  </Link>
                </SwiperSlide>
              </div>
              <div className="slider-arrows banner1-arrow text-center d-flex justify-content-start">
                <div
                  className="swiper-button-prev swiper-prev-arrow"
                  tabIndex={0}
                  role="button"
                  style={clrl}
                  aria-label="Previous slide"
                >
                  {/* SVG for previous button */}
                </div>
                <div
                  className="swiper-button-next swiper-next-arrow"
                  tabIndex={0}
                  style={clrr}
                  role="button"
                  aria-label="Next slide"
                  
                >
                  {" "}
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      {/* <div className="sponsor-section">
        <div className="container">
          <div className="row">
            <h1 style={txt} className="text-center mb-4">Technical Excellence</h1>
            <div className="text-center">
                      <img   alt="" srcset="" style={{width:'100%',opacity:0.6}} src={
                        process.env.PUBLIC_URL +
                        "/images/bg/techstack.png"
                      } />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default TechStack;
