import React from "react";

function Preloader() {
  return (
    <>
     <div class="custom-loading"  >
      <div class="body">
        <div class="ring">
          <img src={process.env.PUBLIC_URL + "/images/social/loader.png"}  />
          <span></span>
        </div>
      </div>
    </div>
      {/* <div className="preloader">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> */}
    </>
  );
}

export default Preloader;
