import React from "react";
import { Link } from "react-router-dom";

function ContactWrap() {
  const scrolltop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const formSubmit = (e) => {
    e.preventDefault();
  };
  const gotomap=()=>window.open('https://www.google.com/maps/place/Mohideen+Sweets+%26+Bakery/@8.704157,77.7071131,18z/data=!4m6!3m5!1s0x3b041237ab1ad641:0x655d1277d4d3263c!8m2!3d8.7040065!4d77.7080981!16s%2Fg%2F124ynk1c6?entry=ttu','_blank')
  return (
    <>
      <div className="contact-section pt-120 pb-120">
        <div className="container">
          <div className="row pb-120 g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn h-100" onClick={gotomap}>
                <span></span>
                <div className="icon">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <div className="text">
                  <h3>Location</h3>
                  <h4>147 Azad road, Sana Complex, </h4>
                  <h4>Melapalayam, Tirunelveli-627 005.</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bx bx-phone-call"></i>
                </div>
                <div className="text">
                  <h3>Phone</h3>
                  <a href="tel:9146223582780">
                    <h4>+91 46223582780</h4>
                  </a>
                  <a href="tel:917904901100">
                    <h4>+91&nbsp;&nbsp;790&nbsp;490&nbsp;1100</h4>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn h-100" >
                <span></span>
                <div className="icon">
                  <i className="bx bx-envelope"></i>
                </div>
                <div className="text">
                  <h3>Email</h3>
                  <a href="" style={{ color: "transparent" }}>
                    a
                  </a>
                  <a href="mailto:info@greenhoodtech.com">
                    <h4>info@greenhoodtech.com</h4>
                  </a>
                  {/* <a href="mailto:info@example.com">
                    <h5>info@example.com</h5>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-lg-end justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Get In Touch.</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div
                className="section-card contact-section-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Contact Us</h2>
                <p>
                  Have questions or feedback? We're here to help! Reach out to
                  us using the information below, and we'll get back to you as
                  soon as possible.
                </p>
                <a
                target="_blank"
                  href="https://maps.app.goo.gl/K9xZkfJiVX2XBaKt5"
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    Location Map
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"
                      }
                      alt="icons"
                    />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-8 text-center">
              <form onSubmit={formSubmit} className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-inner">
                      <input type="text" required placeHolder="Your Name :" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-inner">
                      <input
                        type="text"
                        required
                        placeHolder="Enter Your Email :"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-inner">
                      <input
                        type="text"
                        required
                        placeHolder="Phone Number :"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-inner">
                      <input type="text" required placeHolder="Subject :" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-inner">
                      <textarea placeHolder="Enter Your Message"></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      class="eg-btn hover-btn comment-form-btn"
                    >
                      <span>
                        Send Now
                        <svg
                          width="51"
                          height="13"
                          viewBox="0 0 51 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M44 1.45465V6.5V11.5454C44 11.7194 44.2068 11.8104 44.3351 11.6928L50 6.5L44.3351 1.30722C44.2068 1.18963 44 1.28063 44 1.45465Z" />
                          <path d="M1 6C0.723858 6 0.5 6.22386 0.5 6.5C0.5 6.77614 0.723858 7 1 7V6ZM44.3351 1.30722L44.673 0.938639L44.673 0.938639L44.3351 1.30722ZM50 6.5L50.3378 6.86858L50.7399 6.5L50.3378 6.13142L50 6.5ZM44.3351 11.6928L44.673 12.0614L44.673 12.0614L44.3351 11.6928ZM1 7H44V6H1V7ZM44.5 6.5V1.45465H43.5V6.5H44.5ZM43.9972 1.67579L49.6621 6.86858L50.3378 6.13142L44.673 0.938639L43.9972 1.67579ZM49.6621 6.13142L43.9972 11.3242L44.673 12.0614L50.3378 6.86858L49.6621 6.13142ZM44.5 11.5454V6.5H43.5V11.5454H44.5ZM43.9972 11.3242C44.1897 11.1478 44.5 11.2843 44.5 11.5454H43.5C43.5 12.1544 44.224 12.4729 44.673 12.0614L43.9972 11.3242ZM44.5 1.45465C44.5 1.71568 44.1897 1.85218 43.9972 1.67579L44.673 0.938639C44.224 0.527072 43.5 0.845576 43.5 1.45465H44.5Z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactWrap;
