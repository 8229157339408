import React from "react";

function AboutDetails() {
  return (
    <>
      <div className="col-lg-6 col-md-8 col-sm-8 order-1">
        <div
          className="about-img wow animate fadeInDown"
          data-wow-duration="0.8s"
          data-wow-delay="0.1s"
        >
          <div className="abt-img-tag">
            {/* <div className="icon">
              <img
                src={process.env.PUBLIC_URL + "/images/icon/footer-logo.png"}
                alt="images" 
              />
            </div> */}
            <h5>For <br/> Swift Technical Assistance</h5>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/bg/abt1.png"}
            alt="images"  
          />
        </div>
      </div>
    </>
  );
}

export default AboutDetails;
