import React from "react";
import Breadcrumb from "../../common/Breadcrumb"; 
import JoinUs from "../../common/JoinUs"; 
import ServiceWrap from "./ServiceWrap";
 

function ServicePage() {
  return (
    <>
    {/* <SocialMedia/> */}
      <Breadcrumb name="Our Services" />
      <ServiceWrap />
      {/* <PricingCard /> */}
      <JoinUs padding="pt-120" />
      {/* <Sponsor /> */}
    </>
  );
}

export default ServicePage;
