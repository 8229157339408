import React from "react";
import Footer from "./common/Footer";
import Header from "./common/Header";
import SocialMedia from "./common/social";

function Layout({ children }) {
  return (
    <>
      <Header />
      {/* <SocialMedia/> */}
      {children}
      <Footer />
    </>
  );
}

export default Layout;
