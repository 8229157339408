import React from 'react';

const ProjectCard = ({ title, image }) => {
  const cardStyle = {
    transition: 'transform 0.3s ease-in-out',
  };

  const hoverStyle = {
    transform: 'scale(1.1)',
  };

  return (
    <div style={cardStyle} onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'} onMouseOut={(e) => e.currentTarget.style.transform = 'none'}>
      {/* Project card content (image, title, etc.) */}
      <img src={image} alt={title} />
      {/* <h3>{title}</h3> */}
    </div>
  );
};

export default ProjectCard;

// import React from "react";
// import { Link } from "react-router-dom";

// function ProjectCard(props) {
//   return (
//     <>
//       <div className="l-work-item">
//         <img src={props.image} alt="images" />
//         {/* <div className="work-overlay">
//           <div className="work-title hover-btn">
//             <span> </span>
//             <i className="bi bi-arrow-90deg-right"></i>
//             <h3>
//               <Link
//                 // to={`${process.env.PUBLIC_URL}/project-details`}
//                 // onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
//               >
//                 {props.title}
//               </Link>
//             </h3>
//           </div>
//         </div> */}
//       </div>
//     </>
//   );
// }

// export default ProjectCard;

